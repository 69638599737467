.loader {
  font-size: 0.8rem;
  position: relative;

  .spinner {
    position: relative;
  }
  .img-logo {
    position: absolute;
    width: 100px;
    height: 100px;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    // transform: translate(-50%, -50%);
  }
}

.inline-loader {
  .status {
    word-break: break-word;
    display: flex;
    align-items: center;
  }

  .lds-ripple {
    position: relative;
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
  .lds-ripple div {
    position: absolute;
    border: 3px solid $blue;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple__light div {
    border: 3px solid white !important;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  
  @keyframes lds-ripple {
    0% {
      top: 14px;
      left: 14px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 29px;
      height: 29px;
      opacity: 0;
    }
  }
}

.logo-loader-wrapper {
  .img-logo {
    max-width: 250px;
    min-height: 125px;
  }
  .progress-loader {
    width: 100px;
    height: 5px;
    margin: 0 auto;
    border-radius: 10px;
    border: 4px solid transparent;
    position: relative;
    // padding: 1px;
  }
  .progress-loader:before {
    content:'';
    background-color:#fff; 
    border-radius:10px;
    position:absolute;
    top:0px; 
    right: 0px; 
    bottom:-2px; 
    left:0px;
  }
  .progress-loader .loaderBar {
    position: absolute;
    height: 3px;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: $darkblue;
    width: 20;
    animation: borealisBar 1s linear alternate infinite;
  }

  @keyframes borealisBar {
    0% {
      left: 0%;
      right: 60%;
      width: 30%;
    }
    10% {
      left: 0%;
      right: 40%;
      width: 60%;
    }
    90% {
      right: 0%;
      left: 40%;
      width: 60%;
    }
    100% {
      left: 60%;
      right: 0%;
      width: 30%;
    }
  }
}
