.farms-container, 
.farm-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$header-height});

  .map {
    height: calc(100vh - #{$header-height});
  }

  .farm.card {
    &:last-child {
      margin-bottom: 20px;
    }

    &:hover {
      border-color: $blue;
    }
  }

  .farm {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .w-100;
  }
  
  .selected:not(.card) {
    box-shadow: 0 0 0 0.2rem $water;
  }

  .card-actions {
    display: none;

    .btn {
      @extend .py-0;
    }
  }

  .surveys-toggle {
    @extend .rounded;
    @extend .w-100;
    background-color: $white;
    height: 28px;

    &:hover {
      box-shadow: 0 0 0 0.2rem $lightblue-for-hover;
  
      .card-actions {
        display: block;
      }
    }

    &.btn-link:hover {
      text-decoration: none;
    }
  }

  .search-container {
    .btn-add, 
    .form-control {
      border-radius: 0.4rem;
    }
  }
}

.farms-section {
  .react-datepicker-wrapper {
    @extend .d-block;
  }
  
  .react-datepicker__month-container {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.18);
  }
  
  .react-datepicker__day--selected {
    background-color: $primary;
  }
}