.species {
  .content-bar-inner {
    @include media-breakpoint-up(lg) {
      border-top: 8px solid $landing-border-color;
    }
  }

  .species-title {
    @extend .text-uppercase;
    @extend .fw-semi;
    font-size: $font-size-lg;
  }

  .species-list {
    .species-item {
      .species-name {
        @extend .text-uppercase;
        @extend .fw-semi;
        font-size: $font-size-sm;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-lg;
        }
      }

      .species-img {
        width: $landing-species-image-size;
        height: auto;
        border-radius: 50%;

        @include media-breakpoint-up(lg) {
          width: calc((#{$landing-species-image-size} + 15px) * 1.5);
        }
      }
    }
  }

  .species-item-animation {
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-name: fadeInBottomWithOpacity;
  }

  @keyframes fadeInBottomWithOpacity {
    from {
      opacity: 0;
      transform: translateY(70%);
    }
  }
}