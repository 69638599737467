.navbar {
  @extend .bg-white;
  height: $header-height;
  box-shadow: $box-shadow-sm;
  z-index: 999;

  &-collapse {
    @extend .bg-white;
    @include media-breakpoint-down(md) {
      padding: 1rem;
      text-align: center;
      overflow-y: auto;
      max-height: calc(100vh - #{$header-height});
    }
  }

  &-toggler {
    @extend .px-2;
    @extend .rounded;

    max-height: unset;
  }

  .navbar-brand {
    min-width: 190px;
    display: inline-block;
  }

  .nav-main {
    .nav-link {
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
      font-weight: 400;
      margin-bottom: 0.25rem;

      &:hover, &.active {
        color: $green;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;

        &:hover, &.active {
          color: $blue;
          border-bottom-color: $blue;
        }
      }
    }
  }
}

.guest-header-landing {
  display: block;
  position: fixed;
  top: 0;
  z-index: 2;

  &.header-visible {
    top: 0;
    transition: top 0.4s ease-out;
  }
  
  &.header-hidden {
    top: -$header-height;
    transition: top 0.4s ease-out;
  }

  .navbar {
    max-width: 100%;
    box-shadow: none;
  }

  &.header-on-windows {
    left: 0;
    padding-left: $bootstrap-padding;
    background-color: $white;

    .navbar {
      max-width: calc(100% + #{$bootstrap-padding});
    }
  }
}

.guest-header {
  .navbar {
    box-shadow: 0px 4px 12px $black-25;
  }

  @include media-breakpoint-up(lg) {
    .layout-wrapper {
      padding-left: $layout-wrapper-padding;
      padding-right: $layout-wrapper-padding;
    }
  }
}

.dropdown {
  &-companies {
    @extend .fw-semi;
    font-size: $font-size-lg;
    
    .companies-list{
      max-height: 40vh;
      overflow-y: auto;
    }
    
    .dropdown-menu {
      min-width: 200px;
    }

    .dropdown-toggle {
      &:after {
        @extend .arrow;
        @extend .arrow-gray;
        @extend .arrow-down;
        
        margin-left: 0.75rem;
      }
    }
  }

  &-user {
    .dropdown-menu {
      right: 0;
      left: unset;
    }
  }
}


.sign-in .btn-link {
  @extend .fw-normal;
  font-family: $family-montserrat;
  color: $white;
  background-color: $orange;
  border-color: $orange;
  font-size: $font-size-lg;
  border-radius: 6px;
  
  &:hover {
    color: $landing-darkblue;
    background-color: $white;
    text-decoration: none;
  }
}
