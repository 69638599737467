.modal {
  .modal-content {
    border-radius: 12px;

    .modal-header {
      padding: 24px 36px 0;
      border-bottom: 0;
    }

    .modal-footer {
      --bs-modal-footer-gap: 0;
      padding: 0 36px 24px;
      border-top: 0;

      .btn {
        @extend .text-uppercase;
        @extend .text-end;
        font-size: $font-size-lg;
        text-decoration: none;

        &.btn-link:last-child {
          padding-right: 0;
          margin-right: 0;
        }

        &.btn-link:first-child {
          padding-left: 0;
          margin-left: 0;
        }

        &.btn-link:focus,
        &.btn-link:hover {
          text-decoration: underline;
        }
      }
    }

    .modal-body {
      padding: 24px 36px;

      .modal-btn {
        @extend .text-uppercase;
        @extend .fw-semi;
        margin: 0;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        border-radius: 2rem;
        border: 0;
        font-size: $font-size-lg;
        color: $white;
        padding: 10px 25px;
      }

      .cancel-button {
        .icon {
          color: $blue-50;
          font-size: 21px;
        }
      }

      .remove-button {
        .icon {
          color: $red;
          font-size: 16px;
        }
      }
    }
  }

  .input-wrapper {
    font-size: $font-size-sm;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $blue-50;

    &:first-child {
      border-top: 1px solid $blue-50;
    }

    .file-input__input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .file-input__label {
      background-color: $water;
    }

    .file-upload-btn {
      background-color: $orange;
    }

    .file-name {
      @extend .fw-light;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .spinner-border {
    color: $orange;

    &.wide-border {
      border-width: 4px;
      width: 48px;
      height: 48px;
    }
  }
}

.add-action-modal {
  .risk-levels-dropdown {
    button {
      color: inherit;
      text-transform: unset !important;
      position: relative;
      text-align: left;
      font-size: inherit;

      &:after {
        position: absolute;
        right: 0;
        top: 9px;
      }
    }

    .dropdown-menu {
      padding: 0.4rem 0;
    }

    .dropdown-item {
      padding: 0.2rem 0.8rem;

      &:hover {
        color: $blue;
      }
    }
  }

  .action-form .form-control {
    background-color: $white !important;
    border: 1px solid $water;
    text-align: left !important;
  }

  .rich-text-editor {
    background-color: $white !important;
    border: 1px solid $water;
    border-radius: 1rem;
  }
}
