.alerts {
  position: fixed;
  top: 80px;
  right: 14px;
  z-index: 1056; // 1px more then popups. prev - 998.
  width: 33%;
}

.alert {
  box-shadow: $box-shadow-sm;
}
