.action {
  @extend .col-12;

  .risk-level {
    border-radius: 3px;
    padding: 3px 7px;
    display: inline;
  }
}

.action-form {
  .form-control {
    @extend .bg-lightblue;
  }
  .ButtonGroup__root___3lEAn {
    @extend .bg-white;
  }
}
