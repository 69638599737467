.audit-logs {
  .filters {
    .target-multiselect {
      &__control {
        flex-wrap: nowrap;
        border: 0;
        box-shadow: none;
      }

      &__value-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      &__multi-value {
        min-width: unset;

        &__label {
          text-overflow: unset;
        }

        &__remove:hover {
          background-color: unset;
          color: $error-red;
        }
      }

      &__indicator {
        cursor: pointer;
      }

      &__menu {
        box-shadow: 0 0.5rem 1rem rgba($black, 0.176);
        margin-top: 3px;
      }

      &__option {
        &--is-focused,
        &:active {
          background-color: unset;
          color: $green;
          cursor: pointer;
        }

        &--is-selected {
          background-color: $water;
          color: $white;
        }
      }

      &__placeholder {
        color: $input-placeholder-color;
      }
    }

    .rbt {
      .rbt-input {
        background-color: $white;
        border-radius: 4px;
        min-height: 38px;
        border: 0;
        padding: 4px 10px;
      }

      .dropdown-menu {
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .dropdown-item {
        padding: 8px 12px;
      }
    }
  }

  .log-table {
    font-size: $font-size-xs;

    &-header {
      font-family: $family-open-sans;
      background-color: $activity-header-bg;
      color: $white;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &-row {
      background-color: $white;
      border-bottom: 1px solid $lightblue-text;
      position: relative;

      &:after {
        content: "";
        display: block;
        margin: auto;
        height: 3px;
        width: 0px;
        background: transparent;
        position: absolute;
        bottom: 0;
        transition: width 0.5s ease, background-color 0.5s ease;
      }

      &.active:after {
        width: 100%;
        background: $water;
      }

      &:hover {
        background-color: $activity-row-hover-bg;
        cursor: pointer;
      }
    }

    &-dropdown-row {
      background-color: $activity-row-dropdown-bg;
      color: $activity-row-dropdown-text;
      font-size: $font-size-xxs;
      overflow: hidden;
      transition: height linear 0.2s;
    }

    .timestamp-column {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .pagination-row {
    background-color: $white;
    filter: drop-shadow(0px 0px 2px rgba($black, 0.25));

    .pagination-summary {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba($black, 0.5);
    }

    nav {
      justify-content: flex-end !important;
    }

    .pagination {
      justify-content: end;

      .page-item {
        padding: 0 5px;

        &:last-child {
          padding-right: $bootstrap-padding;
        }

        .page-link {
          border-radius: 4px;
          width: 32px;
          height: 32px;
          text-align: center;
          color: rgba($black, 0.5);

          &:hover {
            cursor: pointer;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .skeleton {
    height: $font-size-lg;
    border-radius: 7px;
    background-color: $lightgray;
  }
}
