.survey-question {
  .survey-dropdown {
    .dropdown-menu {
      .dropdown-item:hover,
      .dropdown-item:focus,
      .dropdown-item:active,
      .dropdown-item.active {
        color: $water;
      }
    }
  }

  @media (min-height: 560px) and (max-height: 680px) {
    .dropdown-menu {
      overflow-y: auto;
      background-color: $white;
    }

    .indicator-title .dropdown-menu {
      max-height: 70vh;
    }

    .category-title .dropdown-menu {
      max-height: 60vh;
    }

    .question-title .dropdown-menu {
      max-height: 50vh;
    }
  }

  .survey-dropdown .dropdown-toggle {
    line-height: 1;
    padding: 0;
    font-weight: 400;
    text-decoration: none;
  }

  .indicator-title .dropdown-toggle {
    font-size: 24px;
  }

  .category-title .dropdown-toggle {
    font-weight: 300;
  }

  .question-title .dropdown-toggle {
    font-size: $font-size-xl;
  }
}

.long-text-form {
  div[class^="RichTextEditor__editor"] {
    overflow-y: auto;
    max-height: 380px;
  }
}