// @import url('https://fonts.googleapis.com/css?family=Fira+Sans');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

// Open Sans font is included via ttf files for PDF reports.
@font-face {
  font-family: 'Open Sans ttf Italic Light';
  src: url('../fonts/OpenSans-LightItalic.ttf')  format('truetype');
  font-style: italic, oblique;
  font-weight: 300, lighter;
}
@font-face {
  font-family: 'Open Sans ttf Italic';
  src: url('../fonts/OpenSans-Italic.ttf')  format('truetype');
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Open Sans ttf Bold';
  src: URL('../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: 600, bold, 700, bolder;
}
@font-face {
  font-family: 'Open Sans ttf Light';
  src: URL('../fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 600, bold, 700, bolder;
}
@font-face {
  font-family: 'Open Sans ttf';
  src: URL('../fonts/OpenSans-Regular.ttf') format('truetype');
}