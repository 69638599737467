.user-container {
  .vertical-divider-right {
    border-right: 1px solid $lightblue-text;
  }
  
  .user-role-column {
    & > .form-group:last-child {
      margin-bottom: 0;
    }
  }
}
