// pdfExport works bad with @extend, font-weight, font-style when font is embedded.
// for good view set font-family for all classes where font is italic or bold or light.
.scored-report-pdf .report {
  .section-score {
    min-width: 55px;
  }

  .farm-details-odd-rows {
    & > *:nth-child(4n+3),
    & > *:nth-child(4n+4) {
      background-color: $lightblue-for-disabled;
    }
  }

  .farm-details {
    font-size: 9px;

    .farm-details-item {
      min-width: 50%;
      line-height: 1;
      padding-top: 4px; //it makes alignment centered in pdf
      padding-bottom: 3px; //it makes alignment centered in pdf
    }
  }

  .section-header-wrap {
    width: 50%;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    
    &-lg {
      width: 70%;
    }
  }

  .section-header-title {
    font-size: $font-size-base;
  }

  .section {
    .tile-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 12px;
  
      .tile-wrapper-report {
        .text,
        .score {
          font-family: $family-open-sans-bold;
          font-size: $font-size-xxs;
        }
  
        .tile {
          margin-bottom: 0;
        }
      }
    }

    .divider {
      height: 15px;
      margin-top: 10px;
      background-color: $lightblue-for-disabled;
    }
  }

  .answer-wrap {
    border: 1px solid $water;
    border-radius: 15px;

    .answer > p:last-child,
    .answer > ul:last-child {
      margin-bottom: 0;
    }

    .answer > ol {
      padding-left: $bootstrap-padding;

      li {
        padding-left: 5px;
      }
    }

    .answer ul {
      padding: 0;
      list-style-type: none;

      li {
        padding-left: $bootstrap-padding;
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          height: 4px;
          width: 4px;
          background-color: $primary;
          border-radius: 50%;
          position: absolute;
          left: 0px;
          top: 6px;
        }

        ul li {  
          &:before {
            background-color: $white;
            border: 1px solid $primary;
          }
        }
      }
    }

    .answer > ul.multi-select-answer {
      display: flex;
      flex-wrap: wrap;

      li {
        min-width: 50%;
        width: 50%;
      }
    }
  }
}