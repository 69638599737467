.footer {
  @extend .text-white;
  @extend .fw-normal;
  background-color: $blue;
  
  .footer-link {
    font-family: $family-montserrat;
    font-size: $font-size-xs;
    color: inherit;
    text-decoration: $link-hover-decoration;
    white-space: nowrap;
  }
}