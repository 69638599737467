.contact-us {
  background-color: $landing-darkgray;
  font-family: $family-montserrat;
  font-size: 18px;
  line-height: 28px;
  color: $white;

  .contact-us-title {
    @extend .text-uppercase;
    font-weight: $font-weight-semibold;
    font-size: 28px;
    line-height: 43px;
    color: $orange;
  }

  .demo-button {
    @extend .text-uppercase;
    display: inline-block;
    background-color: $water;
    border: 2px solid $water;
    color: $white;
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    padding: 10px 50px;

    &:hover {
      border-color: $white-90;
    }
  }

  .address {
    font-size: $font-size-xs;
    line-height: 15px;
    display: flex;
    flex-direction: column;

    .address-divider {
      height: 1px;
      background-color: $white;
    }

    .address-title {
      font-size: $font-size-xs;
      font-weight: $font-weight-semibold;
    }
  }

  .longline-logo {
    height: 70px;

    @include media-breakpoint-up(lg) {
      height: 98px;
    }
  }

  .form {
    .form-control {
      @extend .text-start;
      height: unset;
      padding: 12px 16px;
      margin-bottom: 1.5rem;
      background-color: $landing-form-input-color;
      border-radius: 0;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $white;
      border-bottom: 2px solid transparent;

      &:focus,
      &:active {
        border-color: $landing-get-demo-color;
      }
    }
    
    .btn {
      @extend .w-100;
      background-color: $landing-get-demo-color;
      border: 2px solid $landing-get-demo-color;
      border-radius: $landing-form-border-radius;
      line-height: $landing-form-line-height;

      &:hover {
        border-color: $white-90;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-top: 8vh;
      padding-bottom: 8vh;
    }
  }
}