
.promo {
  color: black;
  margin-top: $header-height;

  .video-container {
    height: calc(100vh - #{$header-height} - 140px);
    width: 100%;
    overflow: hidden;
    position: relative;

    .video {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .full-video-button {
      display: none;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $black-25;
      margin: 0 $bootstrap-gutter;

      .full-video-button-inner {
        svg {
          width: 138px;
          height: 138px;
          color:$white-90;
        }
  
        .play-text {
          font-size: $font-size-xxl;
          color: $white;
        }

        &:hover {
          cursor: pointer;

          svg {
            color:$white;
          }
        }
      }
    }

    &:hover {
      .full-video-button {
        display: flex;
      }
    }
  }

  .headline {
    @extend .fw-semi;
    font-family: $family-montserrat;
    font-size: $font-size-xl;
    line-height: 31px;
    margin: 40px 0 40px 0;

    &.in-view {
      @include animation-up;
      animation-delay: .2s;
    }

    @include media-breakpoint-up(md) {
      font-size: 48px;
      line-height: 75px;
      margin: 60px 0 80px 0;
    }
  }

  .target-container {
    margin-bottom: 40px;

    .target {
      &.in-view {
        @include animation-up;
        animation-delay: .5s;
      }

      @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 58px;
      }
    }

    .target-text {
      font-size: $font-size-xs;
      line-height: $font-size-lg;

      &.in-view {
        @include animation-up;
        animation-delay: .75s;
      }

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 26px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-bottom:100px;
    }
  }
}