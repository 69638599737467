//TODO: refactor it.
#landing-container {
    height: 100vh;
    overflow-x: hidden;
    position: absolute;
    z-index: 0;

  .get-demo-btn {
    background-color: $landing-get-demo-color;
    color: $white;
    border-color: $white;
    font-family: $family-montserrat;

    &:hover {
      background-color: $white;
      color: $landing-darkblue;
      border-color: $landing-get-demo-color;
    }
  }

  .get-demo-fixed {
    position: fixed;
    bottom: 20px;
    right: $layout-wrapper-padding;
    z-index: 2;

    .get-demo-btn {
      font-size: $font-size-sm;
      line-height: $font-size-xxl;
    }
  }

  // layouts
  .layout-header-title {
    @extend .text-uppercase;
    background-color: $orange;
    padding-top: 22px;
    padding-bottom: 140px;
    color: $landing-darkgray;
    text-align: center;
    margin-bottom: 0;
    width: 100%;
    transition: 1s cubic-bezier(0.26, 1, 0.48, 1);

    @include media-breakpoint-up(lg) {
      font-size: $font-size-xxl;
      text-align: left;
      width: unset;
    }

    &.in-view {
      padding-bottom: 22px;
    }
  }

  .layout-container {
    flex-wrap: nowrap;

    .layout-header {
      background-color: $landing-darkgray;

      .layout-header-content {
        height: 100%;
        color: $white;
        font-size: $font-size-sm;
        font-family: $family-montserrat;

        @include media-breakpoint-up(lg) {
          font-size: $font-size-lg;
        }
      }

      @include media-breakpoint-up(lg) {
        height: 228px;
      }

      @media (min-height: 560px) and (max-height: 680px) {
        height: 100%;

        .layout-header-content {
          font-size: $font-size-base;
        }
      }
    }

    .layout-content {
      height: 100%;
      background-color: $white;
    }

    @include media-breakpoint-up(lg) {
      height: 100vh;
    }
  }

  .layout-inner {
    max-width: $layout-inner-max-width;
  }

  .layout-outer {
    max-width: $layout-outer-max-width;
  }

  @include media-breakpoint-up(lg) {
    .layout-wrapper {
      padding-left: $layout-wrapper-padding;
      padding-right: $layout-wrapper-padding;
    }
  }
}