.risk-levels {
  .risks {
    flex-basis: auto;
  }
  
  .risk {
    position: relative;

    .risk-bar {
      height: $landing-risk-level-bar-height;
      border-radius: 2rem;
      border-style: solid;
      border-width: $landing-risk-level-border-size;
    }

    .risk-level {
      @extend .text-uppercase;
      @extend .fw-semibold;
      font-size: $font-size-base;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 1;
      white-space: nowrap;

      @include media-breakpoint-up(xl) {
        position: unset;
        transform: none;
      }
    }

    .risk-line-container {
      position: relative;
    }

    .risk-line {
      display: none;
      height: $landing-risk-level-line-height;
      width: inherit;
      background-color: $blue;
      opacity: 0;
    }

    .risk-level-explanation {
      display: none;
      font-size: $font-size-sm;
      opacity: 0;
    }

    &[active="true"] {
      .risk-level-explanation {
        display: block;
        transition: opacity 0.5s;
        opacity: 1;
      }

      .risk-bar {
        background-color: $white;
        transition: background-color 0.5s;
      }

      @include media-breakpoint-up(lg) {
        .risk-line {
          display: block;
          opacity: 1;
          transition: opacity 0.5s;
        }

        .risk-level-explanation {
          position: absolute;
        }
      }
    }

    &:nth-child(n+4) {
      .risk-level-explanation {
        bottom: 0;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.risk-item-animation .risk-bar,
    &.risk-item-animation .risk-text {
      @include animation-left;
      animation-duration: .5s;
    }
  }
}