// pdfExport works bad with @extend, font-weight, font-style when font is embedded.
// for good view set font-family for all classes where font is italic or bold or light.
.report-container {
  display: flex;
  flex-direction: column;
  font-size: $font-size-sm;
  color: $info;

  .save-pdf-btn {
    .spinner-border {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }

  .pdf-export-wrapper {
    margin: 0 auto;
    max-width: 100%;
    overflow-x: auto;
  }

  .report {
    font-family: $family-open-sans;
    background: $white;
    width: $pdf-page-width;
    padding: 0 $pdf-page-padding;
  }

  .pdf-header {
    padding-top: calc(#{$pdf-page-padding} / 2);

    .pdf-title {
      font-size: $font-size-xl;
    }

    .pdf-subtitle {
      font-size: $font-size-xs;
      font-family: $family-open-sans-light;
    }

    .norm-score {
      font-family: $family-open-sans-bold;
      font-size: $font-size-xs;
    }

    .section-score {
      min-width: 55px;
    }
  }

  .section-header {
    @extend .py-3;

    svg {
      font-size: 1.5rem;
    }
  }

  .text-answer {
    font-size: $font-size-xs;
  }

  .answer {
    font-size: $font-size-xs;
  }

  .pdf-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 10px $pdf-page-padding;
    font-size: $font-size-xs;
  }

  .pdf-font-bold {
    font-family: $family-open-sans-bold;
  }

  .pdf-font-light {
    font-family: $family-open-sans-light;
  }

  .pdf-font-italic {
    font-family: $family-open-sans-italic;
  }

  .pdf-font-italic-light {
    font-family: $family-open-sans-italic-light;
  }
}
