html,
body {
  background: $white;
  color: $blue;
  font-family: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
  height: 100%;
}

button {
  outline: none;
  &:focus {
    outline: none;
  }
}

// TODO: refactor. :not(:where(#landing-container)) is using for remove scrollbar styling on landing page
:not(:where(#landing-container)) {
  /* Let's get this party started */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $lightgray;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $lightgray;
  }
}

#root,
.App {
  height: 100%;
}

a {
  color: $darkblue;
  &:hover {
    text-decoration: none;
    color: $green;
  }

  &:active,
  &:focus {
    text-decoration: none;
  }
}

.page-container {
  background: $lightgray;
  padding-top: $header-height;
  height: 100%;
}

.aqua-container {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  min-height: calc(100vh - #{$header-height});
}

.loader-container {
  @extend .aqua-container;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .pb-5;
}

.header {
  @extend .d-flex;
  @extend .align-items-start;
  @extend .justify-content-between;

  padding: 10px;

  .left-side {
    display: flex;
    flex-direction: row;
  }

  .actions {
    margin-left: auto;
  }

  &-title {
    font-weight: 400;
  }
}

.container-with-survey-detail {
  height: 100%;
}

.main {
  @extend .pt-4;
  flex: 1;
  height: calc(100vh - #{$header-height});
  overflow-y: auto;
  overflow-x: hidden;
}

.hidden {
  // display: none;
  max-width: 0;
}

.opaque {
  opacity: 0%;
}

.center {
  text-align: center;
  justify-content: center;
}

.error {
  color: $red;
}

.cards {
  .card-name-actions {
    .card-actions {
      margin-left: auto;
      align-items: center;
    }
  }
}

.action {
  .action-created {
    @media (min-width: 960px) {
      justify-content: end !important;
    }
  }
}

.box-shadow {
  box-shadow: 2px 2px 2px #c4c6c6;
  margin-bottom: 5px;
  border-radius: 4px;
}

.pagination {
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkmark {
  color: $green;
}

.fw-semi {
  font-weight: $font-weight-semi;
}

.fs-10 {
  font-size: $font-size-xxs; // 0.625rem; //10px
}

.fs-12 {
  font-size: $font-size-xs; // 0.75rem; //12px
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: $font-size-xl; // 1.25rem; //20px
}

.fs-22 {
  font-size: $font-size-xxl; // 1.375rem; //22px
}

.filter-toolbar {
  max-width: 100%;
  width: 100%;

  .nav {
    @extend .flex-nowrap;

    max-width: 100%;
    overflow-x: auto;
  }

  .nav-item {
    @extend .text-nowrap;

    cursor: pointer;
    font-size: $font-size-sm;

    &:hover {
      @extend .fw-semi;
    }
  }

  .dropdown-toggle {
    @extend .text-decoration-none;
    @extend .ps-0;
    @extend .fw-semi;
  }
}

.btn-white {
  @extend .text-decoration-none;
  @extend .w-100;
  @extend .bg-white;
  @extend .text-start;
  @extend .px-3;

  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border-radius: $border-radius-lg;
  line-height: 1.2rem;
}

.btn-back {
  @extend .ps-1;
  @extend .pe-2;
  @extend .pt-0;

  line-height: 1.25;
}

.btn {
  &-355 {
    max-width: 355px;
  }
  &-140 {
    width: 140px;
  }
  &-110 {
    width: 110px;
  }
  &-min-140 {
    min-width: 140px;
  }
  &-40 {
    width: 40px;
    min-width: 40px !important;
    max-width: 40px !important;
  }
}

.btn-link:hover {
  text-decoration: none;
}

.btn-add {
  width: $add-btn-width;
  max-width: $add-btn-width;
}

.bar {
  &-md {
    height: 2px;
  }
  &-lg {
    height: 3px;
  }
  &-xls {
    height: 4px;
  }
  &-xl {
    height: 8px;
  }
}

.action-section-name-bar {
  height: 1px;
}

.radio {
  @extend .rounded-circle;
  @extend .me-2;

  width: 19px;
  min-width: 19px;
  max-width: 19px;
  height: 19px;
  border: 2px solid $white;
}

.risk {
  &-high {
    background-color: $red;
    border-color: $red;
  }
  &-moderate-high {
    background-color: $orange;
    border-color: $orange;
  }
  &-moderate {
    background-color: $yellow;
    border-color: $yellow;
  }
  &-moderate-low {
    background-color: $cyan;
    border-color: $cyan;
  }
  &-low {
    background-color: $green;
    border-color: $green;
  }
  &-undefined {
    background: $gray;
    border-color: $gray;
  }
}

.checkmark,
.filter-items-mobile {
  &-high {
    color: $red;
  }
  &-moderate-high {
    color: $orange;
  }
  &-moderate {
    color: $yellow;
  }
  &-moderate-low {
    color: $cyan;
  }
  &-low {
    color: $green;
  }
}

.comment-risk {
  &-high {
    border: 1px solid $red !important;
  }
  &-moderate-high {
    border: 1px solid $orange !important;
  }
  &-moderate {
    border: 1px solid $yellow !important;
  }
  &-moderate-low {
    border: 1px solid $cyan !important;
  }
  &-low {
    border: 1px solid $green !important;
  }
  &-undefined {
    background: $gray;
  }
}

.filter-items {
  cursor: pointer !important;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  background-color: $white;
  border-radius: 1rem;
  border: 1px solid $primary;
  font-weight: $font-weight-light;
  font-size: 0.875rem;
  line-height: 1.5;

  &.active {
    border-width: 3px;
  }

  &-high {
    border: 1px solid $red !important;
    svg {
      color: $red;
    }
  }
  &-moderate-high {
    border: 1px solid $orange !important;
    svg {
      color: $orange;
    }
  }
  &-moderate {
    border: 1px solid $yellow !important;
    svg {
      color: $yellow;
    }
  }
  &-moderate-low {
    border: 1px solid $cyan !important;
    svg {
      color: $cyan;
    }
  }
  &-low {
    border: 1px solid $green !important;
    svg {
      color: $green;
    }
  }
  &-undefined {
    border: 1px solid !important;
  }

  &.active-filter {
    border-width: medium !important;
  }
}

.border {
  &-high {
    border-color: $red;
  }
  &-moderate-high {
    border-color: $orange;
  }
  &-moderate {
    border-color: $yellow;
  }
  &-moderate-low {
    border-color: $cyan;
  }
  &-low {
    border-color: $green;
  }
  &-undefined {
    border-color: $gray;
  }
}

.text {
  &-high {
    color: $red;
  }
  &-moderate-high {
    color: $orange;
  }
  &-moderate {
    color: $yellow;
  }
  &-moderate-low {
    color: $cyan;
  }
  &-low {
    color: $green;
  }
  &-undefined {
    background: $gray;
  }
}

.smaller {
  font-size: $font-size-sm;
}
.bigger {
  font-size: $font-size-lg;
}
.line-height-1 {
  line-height: 1;
}

.arrow {
  display: inline-block;
  padding: 2.5px;
  border-style: solid;
  border-width: 0 2.5px 2.5px 0;

  &-lg {
    padding: 3.5px;
    border-width: 0 3.5px 3.5px 0;
  }

  &-gray {
    border-color: $gray;

    &:hover {
      border-color: $orange;
    }
  }

  &-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    vertical-align: middle;
  }
}

.bg-lightblue {
  background-color: $lightblue-100 !important;
}

.pseudo-link {
  cursor: pointer;
}

.overflow-y-auto {
  overflow-y: auto;
}

.nav-flex-overflow {
  display: flex;
  overflow-x: auto;
}

.tooltip-inner {
  max-width: 98%;
  min-width: 200px;
}

.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3b6988;
  width: 37px;
  height: 37px;
  left: 50%;
  top: 50%;
  color: #fff;
  border-radius: 50%;
}

.multi-select-wrapper {
  font-size: $font-size-sm;
  border-radius: 10px !important;
  color: $darkblue !important;

  .jss19 {
    color: $darkblue !important;
  }

  .kn-item__selected #checkbox {
    color: $darkblue !important;
  }

  .kn-selection_status__clear_all___3gppf:hover {
    text-decoration: underline !important;
  }

  @media (max-width: 768px) {
    .kn-column__column___3Iwzx {
      min-width: 100%;
    }
  }
}

.no-cursor {
  cursor: unset !important;
}

.underline {
  text-decoration: underline;
}

.odd-rows-white {
  & > *:nth-child(odd) {
    @extend .bg-white;
  }
}

.even-rows-white {
  & > *:nth-child(even) {
    @extend .bg-white;
  }
}

.img-icon {
  &-sm {
    max-width: 20px;
    max-height: 14px;
  }
  &-md {
    max-width: 21px;
    max-height: 20px;
  }
  &-lg {
    max-width: 30px;
    max-height: 24px;
  }
}

.template-upload-container {
  .collapsed {
    max-height: 0;
    overflow-y: hidden;
  }
}

.custom-dropdown {
  .dropdown-menu {
    right: unset;
  }
}
