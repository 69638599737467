.results-tiles {
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .justify-content-center;

  margin-bottom: 3rem;
}

.tile-wrapper-results {
  @extend .px-2;

  min-width: 142px;
  max-width: 176px;
}

.tile {
  background: $white;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.161);
  margin-bottom: 20px;
  text-align: center;
  padding-left: 100%;
  padding-bottom: 100%;
  position: relative;

  .tile-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .text,
  .score {
    @extend .smaller;
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .px-2;

    height: calc(50% - 4px);
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background: $lightgray;
    }
  }

  &-report {
    border-width: 1px;
    border-style: solid;
    box-shadow: none;

    .text,
    .score {
      font-size: 0.8rem;
      line-height: 1.2;
    }

    .score {
      @extend .fw-semibold;
    }
  }
}

.tile-step-wrapper {
  padding-bottom: 40px;
  .bar {
    max-width: 350px;
  }
}
