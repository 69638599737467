//TODO: update during refactoring. :not(:where(.reset-bootstrap-form-group *)) is used because this styles broked form styles on landing
.form-group:not(:where(.reset-bootstrap-form-group *)) {
  margin-bottom: 1.5rem;

  input.form-control,
  select.form-control,
  .form-select,
  .rbt-input.form-control {
    @extend .text-end;
    @extend .fst-italic;
    @extend .fw-light;
    @extend .smaller;

    &.text-start {
      @extend .text-start;
    }
  }

  .form-label {
    @extend .text-uppercase;
    @extend .small;
  }

  .btn {
    @extend .btn-140;
    @extend .border-white;
    @extend .text-uppercase;
  }

  &.btn-toolbar {
    @extend .align-items-start;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $lightblue-for-disabled;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: inherit;
  box-shadow: none;
}

.form-group {
  position: relative;

  .invalid-feedback {
    position: absolute;
    padding-top: 5px;
  }
}

// nav-link
.navbar-light .navbar-nav .nav-link {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

// tabs
.nav-tabs {
  color: $blue;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;

  a.nav-link {
    padding-left: 40px;
    padding-right: 40px;
    border: 0;
    border-radius: 0;
  }

  a.nav-link.active {
    color: $blue;
    border-bottom: 5px solid $darkblue;
  }
}

.user-name {
  color: $gray-600;
  pointer-events: none;
}

// dropdown
.dropdown-menu {
  left: auto;
  right: 0;
}

.nav-dropdown {
  .dropdown-item-header {
    color: $gray-600;
    pointer-events: none;
  }
  ::after {
    display: none;
  }
}

.btn-toolbar {
  .btn {
    margin-right: 5px;
  }
}

// react-bootstrap-typeahead
.rbt {
  &-token {
    background-color: lighten($orange, 30%) !important;
    color: $orange !important;
    &-active {
      background-color: $orange !important;
      color: $white !important;
    }
  }

  &.right {
    .rbt-input-main {
      padding-right: 34px;
    }
    .rbt-input-hint {
      display: none !important;
    }
  }

  .icon-search {
    fill: $blue;
    top: 6px;
    left: 7px;
    width: 20px;
    height: 20px;
  }
}

// FormCheck
.form-switch {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $orange;
    background-color: $orange;
  }
}

.form-text {
  @extend .text-muted;
  @extend .fw-light;
  @extend .text-end;
}

// migrating from bootstrap 4 to bootstrap 5
.btn-link,
a {
  text-decoration: none;
}

.btn-lg {
  line-height: 1.5;
  border-radius: 2rem;
}

.btn-secondary,
.btn-success,
.btn-danger {
  color: $white;

  &:hover,
  &:focus,
  &:disabled {
    color: $white;
  }
}

.btn-outline-info {
  border-color: $white;

  &:hover {
    border-color: $white;
  }
}

button.close {
  padding: 0;
  background-color: initial;
  border: 0;
}

.navbar {
  padding: $navbar-padding-y $navbar-padding-x;
}
