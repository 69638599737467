.survey-detail {
  @extend .px-0;

  height: calc(100vh - #{$header-height});
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  .map {
    min-height: 250px;
    background-color: $lightblue;
  }
  
  .pen-icon-round {
    padding: 3px;
    border-radius: 50%;
  }

  .info {
    @extend .border-start;
    z-index: 777;
    background: $white;
    overflow: auto;
    border-bottom: 16px solid $blue;

    .info-header {
      @extend .px-3;
      @extend .py-2;
      
      h5 {
        @extend .mb-0;

        font-weight: 400;
      }

      .coordinates-label {
        @extend .smaller;
        @extend .text-end;
      }
    }

    .active-survey-media {
      border-radius: 0;
      border-bottom: 2px solid orange;
    }

    .info-body {
      @extend .border-top;
      padding: 0 $bootstrap-gutter;

      .prop {
        &-item {
          @extend .d-flex;
          @extend .smaller;
          @extend .py-2;

          &:nth-child(4n), &:nth-child(4n-1) {
            background-color: $white;
          }
          &:nth-child(4n-2), &:nth-child(4n-3) {
            background-color: $lightgray;
          }
        }
        &-label {
          @extend .fw-semi;
          flex: 2;
        }
        &-value {
          @extend .fw-light;
          @extend .text-end;
        }
      }
    } 

    @include media-breakpoint-down(sm) {
      .info-body {
        .prop {
          &-item {
            &:nth-child(4n), &:nth-child(4n-2) {
              background-color: $white;
            }
            &:nth-child(4n-1), &:nth-child(4n-3) {
              background-color: $lightgray;
            }
          }
        }
      }
    }

  }
}

.survey-detail.hidden {
  width: 0;
}

.btn-toggle-details {
  @extend .ps-1;
  @extend .pe-1;
  @extend .bg-white;
  @extend .text-decoration-none;
  @extend .fw-semibold;
  width: 26px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  &::after {
    @extend .arrow;
    @extend .arrow-gray;
    @extend .arrow-left;

    content: "";
  }
}

.details-wrapper {
  @extend .px-0;
  height: calc(100vh - #{$header-height});
  align-items: center;
  max-height: calc(100vh - #{$header-height});
  
  &-hidden {
    max-width: 17px;
  }
}

