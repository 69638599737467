.survey {
  display: flex;

  .panel {
    background-color: $white;
    height: 100%; // calc(100% - #{$header-height});
    max-height: 100%;
    overflow: auto;
    position: absolute;
    z-index: 12;

    .panel-body {
      @extend .px-0;
    }

    svg {
      margin-right: 0.25rem;
    }
  }

  .survey-actions.open,
  .survey-environment.open {
    width: 400px;
    transition: width 0.1s ease-in;
    max-width: 80%;   
  }

  .survey-actions.open {
    width: 450px;
    max-width: 100%; 
  }
  
  .survey-actions.closed,
  .survey-environment.closed {
    width: 0px;
    transition: width 0.1s ease-out;
  }

  .survey-body {
    width: 100%;
    height: calc(100% - 120px);

    .btn-toolbar .btn {
      background-color: $water;
      color: $white;
      margin-right: $bootstrap-padding;
    }
  }
}