/* Colors */
$water: #33ace3;

$darkblue: #385d7a;
$lightblue: #96afbe;
$lightblue-100: #eef9ff;
$cool: #c6e0e4;
$lightgray: #eaeff3;
$gray: #aaacae;
$darkgray: #464655;
$sky: #1db3d9;
$darkpurple: #475d99;
$logo-primary: #0a4269;
$error-red: #ee5862;
$lightblue-for-hover: #9eb4c5;
$lightblue-for-disabled: #d5dfe7;
$lightblue-text: #c2c9d1;

$white-90: rgba(255, 255, 255, 0.9);
$black-25: rgba(0, 0, 0, 0.25);
$blue-50: rgba(60, 105, 138, 0.5);

/* Sizes */
$header-height: 74px;
$page-cont-padding: 85px;
$border-radius: 1.5rem;
$add-btn-width: 140px;
$auth-form-width: 425px;
$auth-input-height: 50px;
$pdf-page-width: 595px;
$pdf-page-padding: 40px;

$bootstrap-padding: 15px;
$bootstrap-gutter: calc(var(--bs-gutter-x) * 0.5);

/* Font Family */
$family-montserrat: "Montserrat", sans-serif;
$family-fira-sans: "Fira Sans", "Open Sans", Arial, Helvetica, sans-serif;
$family-open-sans: "Open Sans ttf";
$family-open-sans-bold: "Open Sans ttf Bold";
$family-open-sans-light: "Open Sans ttf Light";
$family-open-sans-italic: "Open Sans ttf Italic";
$family-open-sans-italic-light: "Open Sans ttf Italic Light";

/* Font Sizes */
$font-size-xxs: 0.625rem; //10px
$font-size-xs: 0.75rem; //12px
$font-size-xl: 1.25rem; //20px
$font-size-xxl: 1.375rem; //22px
$font-weight-semi: 500;

/** Landing **/
$landing-darkblue: #173466;
$landing-blue: #389ccb;
$landing-gray: #585858;
$landing-darkgray: #343434;
$landing-border-color: #252525;
$landing-text-darkblue: #3b6988;

$landing-form-input-color: #424748;
$landing-form-input-text-color: $white-90;
$landing-form-label-color: rgba(131, 201, 234, 0.55);
$landing-get-demo-color: #8cbf55;

$layout-wrapper-padding: 48px;
$layout-inner-max-width: 820px;
$layout-outer-max-width: 1200px;

$landing-footer-font-size: 0.625rem;
$landing-form-width: 272px;
$landing-form-line-height: 25px;
$landing-form-border-radius: 0.25rem;
$landing-title-divider-height: 38px;
$landing-side-bar-divider-height: 22px;
$landing-action-square-size: 34px;
$landing-side-bar-min-width: 330px;

/* risk-levels */
$landing-risk-level-border-size: 8px;
$landing-risk-level-line-height: 2px;
$landing-risk-level-bar-height: 40px;

/* risk-category */
$landing-risk-indicator-gap: 20px;
$landing-risk-category-icon-size: 28px;
$landing-risk-category-carousel-button-size: 26px;
$landing-risk-category-carousel-spot-size: 9px;
$landing-risk-category-carousel-spot-line-height: 2px;
$carousel-animation-duration: 1s;

/* species */
$landing-species-image-size: 50px;

/** Intelligence portal **/
$controls-position-top: 70px;
$controls-position-bottom: 45px;
$controls-position-left: 30px;
$controls-position-right: 90px;
$controls-height: 34px;
$total-block-size: 255px;

$map-bg-light: rgb(245, 244, 247);
$map-bg-dark: rgb(6, 12, 27);

/** Activity Log **/
$activity-header-bg: #4f4f4f;
$activity-row-hover-bg: #e5f4fc;
$activity-row-dropdown-text: #777777;
$activity-row-dropdown-bg: #efefef;
