// pdfExport works bad with @extend, font-weight, font-style when font is embedded.
// for good view set font-family for all classes where font is italic or bold or light.
.field-template-pdf .report {
  .section-header {
    h5 {
      font-family: $family-open-sans-bold;
    }
  }

  .step-name {
    border-bottom: 4px solid $orange;
    font-size: $font-size-base;
    font-family: $family-open-sans-light;
  }

  .category-name {
    font-size: $font-size-base;
  }

  .text-field {
    border-bottom: 1px solid $water;
  }

  .question-section {
    font-size: $font-size-xs;

    .question-section-title {
      font-family: $family-open-sans-bold;
    }

    .text-field {
      border-bottom: 1px solid $water;
    }

    .single-select-field {
      .single-select-item {
        display: flex;
        align-items: center;

        .icon {
          width: 17px;
          height: 17px;
          border: 2px solid $water;
          border-radius: 1rem;
          margin-right: 10px;
        }

        .text {
          max-width: 85%;
        }
      }

      &.yes-no {
        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .multi-select-field {
      .multi-select-item {
        display: flex;
        align-items: center;

        .icon {
          width: 16px;
          height: 16px;
          min-width: 16px;
          border: 2px solid $water;
          border-radius: 3px;
          margin-right: 10px;
        }

        .text {
          max-width: 85%;
        }
      }
    }

    .text-field {
      .field {
        border-bottom: 1px solid $water;
      }
    }

    .long-text-field {
      .field {
        height: 150px;
        border: 1px solid $water;
        border-radius: 15px;
      }
    }

    .number-field {
      .field {
        border-bottom: 1px solid $water;
      }

      .units {
        font-size: $font-size-xs;
      }
    }
  }
}

.field-template-pdf-multiple-farms .report {
  .text-field {
    border-bottom: 1px solid $gray;
    font-family: $family-open-sans-bold;
  }

  .legend {
    & > div {
      border: 2px solid $gray;
      line-height: 1;
      text-align: center;
      font-size: 11px;
      font-family: $family-open-sans-bold;
      margin: 0 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.circles > div {
      border-radius: 1rem;
      width: 20px;
      height: 20px;
      padding-top: 1px;
    }

    &.squares > div {
      border-radius: 3px;
      width: 16px;
      height: 16px;
      padding-top: 1px;
    }
  }

  .squares > div {
    border: 2px solid $gray;
    border-radius: 3px;
    line-height: 1;
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  .question-section {
    .single-select-field {
      .single-select-item {
        .icon {
          border-color: $gray;
          margin-right: 5px;
        }

        .text {
          margin-left: 8px;
        }
      }
    }

    .multi-select-field {
      .multi-select-item {
        .icon {
          border-color: $gray;
          margin-right: 5px;
        }

        .text {
          margin-left: 8px;
        }
      }
    }

    .text-field {
      .field {
        border-color: $gray;
        font-family: $family-open-sans-bold;
      }
    }

    .long-text-field {
      .field {
        height: 340px;
        border-color: $gray;
        color: $water;
        font-family: $family-open-sans-bold;

        & > div {
          height: calc(100%/4 - 10px);
          box-sizing: border-box;
          margin: 10px $bootstrap-padding 0 $bootstrap-padding;
          position: relative;

          &:not(:last-of-type) {
            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              width: 45px;
              height: 1px;
              background-color: $gray;
            }

            &::before {
              left: -$bootstrap-padding;
            }

            &::after {
              right: -$bootstrap-padding;
            }
          }
        }
      }
    }

    .number-field {
      .field {
        border-color: $gray;
        font-family: $family-open-sans-bold;
      }
    }
  }
}