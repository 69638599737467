.intelligence {
  position: relative;
  overflow: hidden;

  .mapboxgl-map {
    height: calc(100vh - #{$header-height}) !important;

    .mapboxgl-control-container {
      .mapboxgl-ctrl {
        margin: 0;
      }

      .mapboxgl-ctrl-bottom-left {
        bottom: $controls-position-bottom;
        left: $controls-position-left;
      }

      .mapboxgl-ctrl-top-right {
        top: $controls-position-top;
        right: $controls-position-right;

        @include media-breakpoint-down(sm) {
          top: $controls-position-bottom;
          right: $controls-position-left;
          left: $controls-position-left;
        }
      }

      .mapboxgl-ctrl-geocoder {
        width: $total-block-size;
        border-radius: $border-radius;

        @include media-breakpoint-down(sm) {
          width: 100%;
          max-width: 100%;
        }

        &--input {
          height: $controls-height;
          font-family: $family-fira-sans;
          color: $primary;
        }

        &--icon {
          top: 7px;
        }

        &--powered-by {
          display: none !important;
        }

        .suggestions {
          > li > a {
            color: $primary;
          }

          > .active > a,
          > li > a:hover {
            color: $green;
            background-color: unset;
          }
        }
      }
    }

    .mapboxgl-popup {
      font-family: $family-fira-sans;
      width: 240px;

      .mapboxgl-popup-content {
        padding: 15px 15px 5px 15px;
      }

      .popup-title {
        color: $water;
        text-decoration: underline;
        font-size: $font-size-base;

        &:focus {
          outline: none;
        }
      }

      .popup-table .row {
        background-color: rgba($lightblue-100, 0.53);
      }

      .rounded-point {
        @extend .rounded-circle;
        width: 8px;
        height: 8px;
      }

      .bar {
        max-width: 100%;
      }
    }
  }

  &-menu {
    position: absolute;
    top: $controls-position-top;
    left: $controls-position-left;
    width: 200px;
    font-weight: $font-weight-semibold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;

    @include media-breakpoint-down(sm) {
      top: $controls-position-bottom;
      margin-top: calc(#{$bootstrap-padding} + #{$controls-height});
      width: calc(100% - #{$controls-position-left}*2);
    }
  }

  &-accordion {
    font-size: 12px;
    line-height: 18px;

    .accordion-button {
      width: 100%;
      padding: 8px 16px;
      color: $white;
      background-color: shade-color($water, 10%);
      font-weight: 600;
      letter-spacing: 1.2px;
      font-size: 12px;
      line-height: 18px;
      position: relative;

      &.collapsed {
        background-color: $water;
      }

      & > span {
        position: relative;
        left: 0;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }

    .accordion-item {
      background-color: transparent;
      overflow: hidden;

      &:first-of-type {
        border-radius: $border-radius $border-radius 0 0;

        .accordion-button {
          border-radius: $border-radius $border-radius 0 0;
          transition: unset;
          transition-delay: unset;

          &.collapsed {
            transition: all .2s ease-in-out;
            transition-delay: .25s;
            border-radius: $border-radius;
          }
        }
      }

      &:last-of-type {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    .accordion-body {
      padding: 0;

      .btn {
        border-radius: 0;
        color: $white;
        width: 100%;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.2px;
      }
    }

    &.filter {
      .btn {
        color: $primary;
      }

      .accordion-button {
        color: $primary;
        background-color: shade-color($gray-100, 10%);

        &.collapsed {
          background-color: $gray-100;
        }
      }
    }
  }

  &-totals {
    position: absolute;
    bottom: $controls-position-bottom;
    right: $controls-position-right;

    &--wrapper {
      position: relative;
    }

    &--inner {
      min-width: $total-block-size;
      height: $total-block-size;
      width: $total-block-size;
      padding: $bootstrap-padding;
      background-color: $white;
      border-radius: 1rem;

      .number {
        font-size: 35px;
        color: $water;
        font-weight: $font-weight-semibold;
      }

      .map-type-name {
        font-size: $font-size-xl;
        font-weight: lighter;
      }

      .dropdown-toggle {
        padding: 0;

        &::after {
          margin-left: 8px;
        }
      }

      .dropdown-menu {
        max-height: 300px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          background: $white;
        }
      }

      .risk {
        font-size: $font-size-xs;
        margin-bottom: 0px;
      }
    }

    &--toggle {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      border: 0;
      height: 40px;
      width: 40px;
      background-color: rgba($water, 0.18);
      border-top-right-radius: 0;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 1rem;
      transition: all .2s ease-out-in;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    @include media-breakpoint-down(sm) {
      right: $controls-position-left;

      &.show {
        .intelligence-totals--inner {
          animation: 0.2s circle reverse;
        }

        .risk:last-of-type {
          padding-bottom: 30px;
        }
      }

      &.hide {
        .intelligence-totals--inner {
          height: 0;
          width: 0;
          padding: 0;
          overflow: hidden;
        }

        .intelligence-totals--toggle {
          background-color: rgba($water, 1);
          color: $white;
          width: 65px;
          height: 65px;
          border-radius: 20px;
          font-size: 35px;
        }
      }
    }
  }
}

@keyframes circle {
  0% { clip-path: circle(100% at 100% 100%); }
  30% { clip-path: circle(75% at 100% 100%); }
  100% { clip-path: circle(0% at 100% 100%); }
}