.mapboxgl-map {
  width: 100%;
  background: linear-gradient($map-bg-dark 50%, $map-bg-light 50%);

  .mapboxgl-marker {
    cursor: pointer;
  }

  .mapboxgl-control-container {
    .mapboxgl-ctrl-logo,
    .mapboxgl-ctrl-attrib {
      display: none !important;
    }

    .mapboxgl-ctrl-styletoggle {
      background-image: url(../images/StyleToggleIcon.svg);
    }

    .mapboxgl-ctrl-zoom {
      background-image: url(../images/ZoomToggleIcon.svg);
    }

    .mapboxgl-ctrl {
      float: none;
      margin: $bootstrap-padding;

      button:not(:disabled):hover {
        background-color: rgba($black, 0.15);
      }
    }
  }
}

.geocoder-container {
  .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: unset;
    background-color: rgba($white, 0.6);
    background-clip: padding-box;
    border: 0 solid $gray-400;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 2rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .mapboxgl-ctrl-geocoder--input {
    padding: 0.25rem 1.25rem;
    padding-right: 34px;
    width: 100%;
    height: 29px;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.5;
    color: $blue;
    font-family: $family-fira-sans;
    text-align: right;

    &::placeholder {
      color: $blue;
    }

    &:focus {
      outline: none;
      color: $blue;
    }
  }

  .mapboxgl-ctrl-geocoder--icon {
    top: 6px;
    fill: $blue;
  }

  .mapboxgl-ctrl-geocoder--pin-right > .mapboxgl-ctrl-geocoder--button {
    background-color: inherit;
    top: 4px;

    &:not(:disabled):hover {
      background-color: inherit;
    }
  }

  .mapboxgl-ctrl-geocoder--powered-by {
    display: none;
  }

  .disabled .mapboxgl-ctrl-geocoder {
    background-color: rgba($blue, 0.1);
    pointer-events: none;

    .mapboxgl-ctrl-geocoder--input::placeholder {
      color: rgba($blue, 0.3);
    }

    .mapboxgl-ctrl-geocoder--icon {
      fill: rgba($blue, 0.3);
    }
  }

  .suggestions-wrapper .suggestions {
    @extend .dropdown-menu;

    & > li > a {
      color: $blue;
    }

    & > .active > a,
    & > li > a:hover {
      background-color: unset;
      color: $green;
    }
  }

  .mapboxgl-ctrl-geocoder--powered-by {
    display: none !important;
  }
}
