.surveys-container {
  .search-container {
    .btn-add,
    .form-control {
      border-radius: 0.4rem;
    }
  }

  .filter-toolbar.surveys-list {
    max-width: 100%;

    .filter-toolbar-chips {
      .nav-item {
        display: flex;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
        background-color: $white;
        border-radius: 1rem;
        border: 1px solid $primary;
        font-weight: $font-weight-light;

        &.active {
          border-width: 3px;
        }

        &:hover {
          border-color: $orange;
        }
      }
    }
  }

  .farm-with-survey {
    .farm.accordion-item {
      background-color: transparent;

      .accordion-body {
        padding: 0 $grid-gutter-width / 2 $grid-gutter-width / 2;

        .score-titles {
          background-color: rgba(255, 255, 255, 0.4);
        }

        & > div:nth-child(2) {
          & > div:nth-child(odd) {
            background-color: rgba(255, 255, 255, 0.4);
          }
        }

        .survey-name {
          line-height: 38px;
        }
      }

      .accordion-header {
        @extend .p-0;
        background: transparent;
      }

      .accordion-button {
        @extend .btn-white;
        @extend .d-flex;
        @extend .rounded;
        box-shadow: none;

        &:focus,
        &:active {
          box-shadow: none !important;
        }

        .questions & {
          @extend .px-0;
        }
      }

      .btn-generate {
        display: inline-block;
      }
    }
  }

  .video-modal {
    z-index: 1000;
    position: fixed;
    bottom: 0px;
    left: 0px;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    @media (min-width: 769px) {
      bottom: 3rem;
      left: 80px;
      width: auto;
    }
    .video-toast {
      background-color: #475d99;
      width: 300px;
      min-height: 200px;
      @media (max-width: 768px) {
        min-width: 75%;
      }
      @media (max-width: 576px) {
        min-width: 100%;
      }
      .video-wrapper {
        height: 70%;
        width: 100%;
        #vidObj {
          min-height: 163px;
        }
        .video-loader {
          height: 150px;
        }
      }
    }
  }

  .media-buttons {
    z-index: 10;
    position: fixed;
    bottom: 3rem;
    left: 0px;
    width: auto;
    height: 200px;

    background-color: $darkpurple;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 4px 4px $black-25;
    &--disabled {
      background-color: $white;
      color: $darkpurple;
    }

    @media (max-width: 768px) {
      display: none;
    }

    .input-group {
      height: 100%;
    }
    .media-heading--disabled {
      font-size: $font-size-xs;
      color: $darkpurple;
    }
  }
}

.actions-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$header-height});

  .map {
    height: calc(100vh - #{$header-height});
  }

  .action {
    background-color: #f2f5f8;
    border-radius: 4px;
  }
}

.filters-by-risk-level {
  .filters-accordion {
    .action-filter-toggle {
      @extend .d-block;

      box-shadow: none;

      &:focus,
      &:active {
        box-shadow: none !important;
      }

      .questions & {
        @extend .px-0;
      }
    }

    .accordion-button {
      @extend .pb-0;
      @extend .px-0;
      @extend .d-flex;
      background-color: transparent;

      &::after {
        display: none;
      }
    }

    .accordion-item {
      background-color: transparent;
    }
  }
}
