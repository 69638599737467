.actions {
  .farms-with-actions {
    flex: 1;
    overflow-y: auto;
    z-index: 200;

    .farms,
    .actions {
      padding: 0 10px;
      overflow-y: auto;
    }

    .action-header {
      .accordion-button {
        flex-wrap: wrap;
      }
    }
  }
}
