.text-water {
  color: $water;
}

.auth-container {
  height: 100vh;
  overflow: hidden;
  background-color: $white;

  @include media-breakpoint-up(md) {
    background-color: $lightgray;
  }

  .auth-container-inner {
    @extend .d-flex;
    @extend .justify-content-center;
    height: calc(100vh - #{$header-height});
  }
}

.form-container-wrapper {
  @extend .d-flex;
  @extend .align-items-md-center;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: unset;
  }
}

.form-container {
  background-color: $white;
  padding-top: 40px;
  padding-bottom: 28px;
  width: 100%;
  
  @include media-breakpoint-up(md) {
    width: $auth-form-width;
    box-shadow: 4px 4px 12px $black-25;
    border-radius: 6px;
  }

  .headline {
    @extend .text-center;
    @extend .text-water;
    font-size: 24px;
  }
  
  .request-demo-text {
    font-weight: $font-weight-light;
  
    .request-demo-link {
      text-decoration: underline;
  
      &:hover {
        color: inherit;
      }
    }
  }
  
  .login-form {
    .form-label {
      font-weight: $font-weight-semibold;
    }
  
    .form-control {
      @extend .mb-4;
      height: $auth-input-height;
      background-color: $primary;
      color: $white;
      border-radius: 2rem;
      font-size: 14px;
      font-family: inherit;
      font-weight: $font-weight-light;
      line-height: 1.5rem;
      filter: none;

      &[type="password"] {
        font-family: caption;
        letter-spacing: 3px;
      }

      &::placeholder {
        @extend .fst-italic;
        font-size: $font-size-sm;
        color: inherit;
        font-family: $family-fira-sans;
        letter-spacing: normal;
      }

      &:-webkit-autofill {
        // Removing input background color for browser autocomplete input:-internal-autofill-selected
        transition: 
          background-color 5000s ease-in-out 0s, 
          color 5000s ease-in-out 0s;
      }
    }

    .password-wrapper {
      position: relative;

      .show-password-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: calc(#{$auth-input-height} * 1.25);
        color: $white;
        font-size: $font-size-lg;
      }
    }
  
  
    .forgot-password-link {
      text-decoration: underline;
      font-weight: $font-weight-normal;
    }

    .submit {
      height: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      .button-submit {
        @extend .fw-semi;
        border-color: $white;
        font-size: $font-size-lg;
        line-height: 22px;
      }

      .error-message {
        height: 2rem;
        color: $error-red;
        font-weight: $font-weight-normal;
      }
    }

  
    .explanation-text {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
