.layout-sticky {
  .z-index-1 {
    z-index: 1;
  }

  .layout-sticky-title {
    position: sticky;
    top: 0;
  }

  .layout-sticky-bg {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-image 1s cubic-bezier(0.32, 0.94, 0.6, 1), transform 1s cubic-bezier(0.26, 1, 0.48, 1);
    transition-delay: 0s, 0s;
  }

  .layout-sticky-inner {
    height: 100vh;
    font-family: $family-fira-sans;
    font-size: $font-size-xl;
    font-weight: $font-weight-normal;
    line-height: 1.5;
    color: $white;

    .layout-sticky-inner-text {
      position: relative;
      opacity: 0;
      
      &.in-view {
        opacity: 1;
        transition: 1s opacity linear;
        transition-delay: .2s;
      }
    }

    .layout-sticky-inner-headline {
      @extend .fw-semibold;
      position: relative;
      line-height: $landing-action-square-size;
      padding-left: 4rem;
      -webkit-clip-path: inset(0% 100% 0% 0%);
      clip-path: inset(0% 100% 0% 0%);

      &.in-view {
        clip-path: inset(0% 0% 0% 0%);
        transition: 1s clip-path linear;
        transition-delay: 1s;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: $landing-action-square-size;
        height: $landing-action-square-size;
        background: $water;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 5rem;
      }
    }
  }
}

// for sticky animation
.slide {
  position: sticky;
  top: 0;
  height: 100vh;
}

.slide-lg {
  position: relative;

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 0;
    height: 100vh;
  }
}

#slide1 {
  background-image: url(../../../images/landing/landing-bg-1.jpeg);
  transform: translateX(0%) translateY(0%) scale(1);
}

#slide2 {
  background-image: url(../../../images/landing/landing-bg-2.jpeg);
  transform: translateX(-1%) translateY(-1%) scale(1.05);
}

#slide3 {
  background-image: url(../../../images/landing/landing-bg-3.jpeg);
  transform: translateX(-2%) translateY(-2%) scale(1.1);
}