// bootstrap
@import "./node_modules/bootstrap/scss/mixins.scss";
@import "./node_modules/bootstrap/scss/functions.scss";

// bootstrap variables overrides
@import "bs_variables";

@import "./node_modules/bootstrap/scss/bootstrap.scss";

//react-datepicker
@import "~react-datepicker/dist/react-datepicker.css";

// custom variables
@import "variables";
@import "fonts";

@import "~react-bootstrap-typeahead/css/Typeahead.css";

@import "base";
@import "bootstrap-overrides";
@import "mapbox-gl-overrides";
@import "components/action";
@import "components/modal";
@import "components/actions";
@import "components/alerts";
@import "components/header";
@import "components/farms-container";
@import "components/loader";
@import "components/pdf-reports/report.scss";
@import "components/pdf-reports/survey-pdf.scss";
@import "components/pdf-reports/template-pdf.scss";
@import "components/survey";
@import "components/survey-detail";
@import "components/survey-question";
@import "components/survey-results";
@import "components/surveys-container";
@import "components/users";
@import "components/tile";
@import "components/intelligence";
@import "components/auth";
@import "components/activity-log";
@import "components/landing/main";
