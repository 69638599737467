.risk-categories {
  .risk-carousel-container {
    overflow: hidden;
  }

  .risk-category {
    .category-name {
      @extend .text-uppercase;
      font-weight: $font-weight-bolder;
      font-size: $font-size-lg;
      line-height: $landing-risk-category-icon-size;
    }

    .category-icon {
      width: $landing-risk-category-icon-size;
      height: auto;
    }

    .risk-description {
      font-weight: $font-weight-light;
      font-size: $font-size-xs;
      line-height: $font-size-lg;
    }
  }

  .risk-category-indicators {
    gap: $landing-risk-indicator-gap;
    max-width: 450px;
    margin: 0 auto;

    .risk-category-container {
      width: calc(50% - #{$landing-risk-indicator-gap}/2);
      max-width: 200px;
    }

    .risk-category-indicator {
      position: relative;
      padding-left: 100%;
      padding-bottom: 100%;
      color: $landing-text-darkblue;
      box-shadow: 0 5px 5px $black-25;

      .risk-indicator-inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .risk-indicator-name {
        height: 50%;
      }
      
      .risk-indicator-level {
        @extend .text-uppercase;
        @extend .fw-semi;
        height: 50%;
      }
    }
  }

  .carousel-buttons {
    transform: translateY(-10px);

    .button {
      background-color: $landing-darkgray;
      border-color: $landing-darkgray;
      height: $landing-risk-category-carousel-button-size;
      width: $landing-risk-category-carousel-button-size;
      padding: 0;
    }
  }

  .dot {
    .dot-spot-container {
      position: relative;
      height: 20px;

      .dot-spot-line {
        position: absolute;
        top: 3px;
        left: calc(-100% - #{$bootstrap-padding} * 2);
        width: 0;
        height: $landing-risk-category-carousel-spot-line-height;
        background-color: $orange;
      }
    }

    .dot-spot {
      position: absolute;
      left: 0;
      background-color: $orange;
      width: $landing-risk-category-carousel-spot-size;
      height: $landing-risk-category-carousel-spot-size;
      z-index: 1;

      &:hover {
        cursor: pointer;
      }
    }

    .dot-name {
      width: calc(100% + #{$bootstrap-padding} * 2);
      font-family: $family-fira-sans;
      font-size: $font-size-sm;
      line-height: $font-size-base;
      transform: translateX(calc((-50% + 6px)));

      &:hover {
        cursor: pointer;
      }

      @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
      }
    }

    &[active='true'] .dot-spot {
      background-color: $water;
    }

    &:first-of-type .dot-spot-line {
      transform: rotate(180deg);
      transform-origin: 0% 50%;
      left: 0;
      width: 50vw;
    }
  }
}