.survey-results {
  .report-download-btn {
    background-color: $darkpurple;
    width: 100px !important;
    cursor: pointer;
  }

  .header {
    @extend .align-items-start;
    @extend .justify-content-start;
  }

  .results {
    @extend .mt-3;

    @include media-breakpoint-up(lg) {
      padding-left: 43px;
    }
  }

  .filter-toolbar {
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .align-items-start;
  }

  .section-header {
    @extend .text-center;
    @extend .mb-4;
    div {
      @extend .fw-semi;
      font-size: $font-size-lg;
    }
    svg {
      @extend .mb-1;
      font-size: 1.55rem;
    }
  }
}

.survey-result {
  @extend .px-4;
  @extend .py-3;

  .header {
    &-title {
      font-size: 36px;
    }

    &-description {
      font-size: $font-size-xl;
    }
  }

  .content {
    padding: 0 15px;
  }

  .score-row {
    align-items: flex-end;
  }

  .score {
    font-size: 40px;
    color: $lightblue-text;
  }

  .farm-with-survey .accordion-item .accordion-header .accordion-button {
    @extend .px-0;
    @extend .py-1;
    @extend .bg-white;
  }

  .show-all {
    .collapse:not(.show) {
    display: block;
    }

    .accordion-button::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform);
    }
  }
}
