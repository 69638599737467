// animation mixins

@mixin animation-left {
  opacity: 0;
  transform: translateX(-200px);
  animation-name: effects-system-left;
  animation-duration: .5s;
  animation-timing-function: cubic-bezier(0.26, 1, 0.48, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@mixin animation-up {
  opacity: 0;
  transform: translateY(200px);
  animation-name: effects-system-up;
  animation-duration: .5s;
  animation-timing-function: cubic-bezier(0.26, 1, 0.48, 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@mixin animation-timeline-left {
  animation-name: timeline-effect-left;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes effects-system-left {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes effects-system-up {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes timeline-effect-left {
  0% {
    width: 0;
  }
  100% {
    width: calc(100% + #{$bootstrap-padding} * 2);
  }
}
